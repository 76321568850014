import React from 'react';
import './round-button.scss';
import { Button } from '@missionlabs/smartagent-app-components';

interface Props {
    size?: 'small' | 'medium' | 'large';
    onClick?: () => void;
    className?: string;
}

const RoundButton: React.FC<Props> = ({ size, onClick, children, className }) => {
    return (
        <Button onClick={onClick} className={`${size || 'medium'} round-button ${className}`}>
            {children}
        </Button>
    );
};

export default RoundButton;
