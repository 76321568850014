import React, { useEffect, useState } from 'react';
import {
    useAudioVideo,
    useLocalVideo,
    useMeetingManager,
    useSelectVideoInputDevice,
    useToggleLocalMute,
    useVideoInputs,
} from 'amazon-chime-sdk-component-library-react';
import CameraIcon from 'images/camera.svg';
import BlurIcon from 'images/blur.svg';
import EndIcon from 'images/end.svg';
import MuteIcon from 'images/mute.svg';
import MutedIcon from 'images/muted.svg';
import VideoIcon from 'images/video.svg';
import NoVideoIcon from 'images/no-video.svg';
import './controls.scss';
import RoundButton from 'components/RoundButton';
import { DevicePermission } from 'amazon-chime-sdk-js';

interface Props {
    blurControl?: boolean;
    onToggleBlur?: () => void;
    onLeave: () => void;
}
const Controls: React.FC<Props> = ({ blurControl, onToggleBlur, onLeave }) => {
    const { muted, toggleMute } = useToggleLocalMute();
    const selectVideo = useSelectVideoInputDevice();
    const { devices, selectedDevice } = useVideoInputs();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const [changingDevice, setChangingDevice] = useState(false);
    const av = useAudioVideo();
    const mm = useMeetingManager();

    const onSwitch = async () => {
        const device = devices.find(
            d => d.deviceId !== selectedDevice && d.label.indexOf('infrared') === -1
        );
        if (device) {
            const permission = await av?.chooseVideoInputDevice(device);
            //Sometimes we get PermissionDeniedByBrowser when the local video needs stopping first so need to switch video off then on
            if (permission === DevicePermission.PermissionDeniedByBrowser) {
                console.log('trying to toggle video to change device');
                await toggleVideo();
                await selectVideo(device.deviceId);
                setChangingDevice(true);
            } else {
                mm.selectedVideoInputDevice = device.deviceId;
                //@ts-ignore
                mm.publishSelectedVideoInputDevice();
            }
        }
    };

    useEffect(() => {
        if (selectedDevice && !isVideoEnabled && changingDevice) {
            toggleVideo();
        }
        if (changingDevice) setChangingDevice(false);
        // eslint-disable-next-line
    }, [selectedDevice, isVideoEnabled, changingDevice]);

    const onToggleVideo = () => {
        toggleVideo();
    };

    const isVE = isVideoEnabled || changingDevice;

    return (
        <div className="controls">
            {blurControl && (
                <RoundButton size="large" className="controls__blur" onClick={onToggleBlur}>
                    <img src={BlurIcon} alt="Blur" />
                </RoundButton>
            )}
            {muted ? (
                <RoundButton size="large" className="controls__muted" onClick={toggleMute}>
                    <img src={MutedIcon} alt="Muted" />
                </RoundButton>
            ) : (
                <RoundButton size="large" className="controls__mute" onClick={toggleMute}>
                    <img src={MuteIcon} alt="Mute" />
                </RoundButton>
            )}
            {isVE ? (
                <RoundButton size="large" className="controls__video" onClick={onToggleVideo}>
                    <img src={VideoIcon} alt="Video On" height={28} width={28} />
                </RoundButton>
            ) : (
                <RoundButton size="large" className="controls__videooff" onClick={onToggleVideo}>
                    <img src={NoVideoIcon} alt="Video Off" height={28} width={28} />
                </RoundButton>
            )}
            {devices.length > 1 && isVE && (
                <RoundButton size="large" className="controls__switch" onClick={onSwitch}>
                    <img src={CameraIcon} alt="Swap Camera" />
                </RoundButton>
            )}
            <RoundButton size="large" className="controls__end" onClick={onLeave}>
                <img src={EndIcon} alt="End" />
            </RoundButton>
        </div>
    );
};

export default React.memo(Controls);
