import React, { useEffect, useMemo, useState } from 'react';
import { MeetingResponse } from 'services/api';
import { Button } from '@missionlabs/smartagent-app-components';
import { ResizableBox } from 'react-resizable';
import AddIcon from 'images/add.svg';
import './agent-meeting.scss';
import {
    useMeetingManager,
    useLocalVideo,
    useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { OtherTiles, SelectedTile } from 'components/SelectedTiles';
import Controls from 'components/Controls';
import { useSelectedVideo } from 'context/SelectedVideo';

interface Props {
    meeting: MeetingResponse;
    onDisconnect: (attendeeId: string) => void;
    onAdd: () => void;
}

const AgentMeeting: React.FC<Props> = ({ meeting, onAdd, onDisconnect }) => {
    const [loading, setLoading] = useState(false);
    const [blur, setBlur] = useState(true);
    const meetingManager = useMeetingManager();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { setLocalAttendeeId } = useSelectedVideo();
    const { muted, toggleMute } = useToggleLocalMute();

    useEffect(() => {
        setLocalAttendeeId(meeting.Attendee.AttendeeId);
        join();
        // eslint-disable-next-line
    }, [meeting]);

    const join = async () => {
        if (!meeting) return;
        setLoading(true);
        await meetingManager.join({
            meetingInfo: meeting.Meeting,
            attendeeInfo: meeting.Attendee,
        });
        await meetingManager.start();
        setLoading(false);
    };

    useEffect(() => {
        if (!loading && !isVideoEnabled) {
            toggleVideo();
        }
        //mute when in development
        if (!loading && !muted && process.env.NODE_ENV !== 'production') {
            toggleMute();
        }
        // eslint-disable-next-line
    }, [loading]);

    const onLeave = () => {
        meetingManager.leave();
    };

    const width = useMemo(() => document.body.clientWidth - 200, []);

    return (
        <section className="agent-meeting">
            <div className="agent-meeting__main">
                <ResizableBox
                    width={width}
                    height={Infinity}
                    minConstraints={[100, 140]}
                    maxConstraints={[width, Infinity]}
                >
                    <div className="agent-meeting__selected">
                        <SelectedTile blur={blur} onRemove={onDisconnect} />
                    </div>
                </ResizableBox>
                <Controls blurControl onToggleBlur={() => setBlur(!blur)} onLeave={onLeave} />
            </div>
            <div className="agent-meeting__videos">
                <Button className="agent-meeting__add" onClick={onAdd}>
                    <img src={AddIcon} alt="Add Participant" />
                    <span>Add Participant</span>
                </Button>
                <OtherTiles blur={blur} onRemove={onDisconnect} />
            </div>
        </section>
    );
};

export default AgentMeeting;
