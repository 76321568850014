import React, { useEffect, useContext, useRef, useState } from 'react';
import SmartAgent, { Contact, User } from '@missionlabs/smartagent-app-components/dist/smartagent';
import axios, { AxiosRequestConfig } from 'axios';

interface ISmartAgentContext {
    contact?: Contact;
    sa: SmartAgent;
    agent?: User;
}

const isEmbedded = window.parent !== window;
export const SmartAgentContext = React.createContext<ISmartAgentContext>({} as any);

const SmartAgentProvider: React.FC = ({ children }) => {
    const sa = useRef<SmartAgent>(new SmartAgent());
    const [contact, setContact] = useState<Contact>();
    const [agent, setAgent] = useState<User>();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const contact = await sa.current?.init();
        setContact(contact);

        sa.current?.on('contact_updated', (contact: Contact) => {
            setContact(contact);
        });

        setAgent(await sa.current?.getUser());
    };

    useEffect(() => {
        const interceptor = axios.interceptors.request.use(attachToken);
        return () => axios.interceptors.request.eject(interceptor);
    }, []);

    const attachToken = async (config: AxiosRequestConfig) => {
        if (!sa.current || !isEmbedded) return config;
        config.headers = {
            ...config.headers,
            'X-Amz-Security-Token': await sa.current.getToken(),
        };
        return config;
    };

    return (
        <SmartAgentContext.Provider
            value={{
                sa: sa.current,
                contact,
                agent,
            }}
        >
            {children}
        </SmartAgentContext.Provider>
    );
};

const useSmartAgent = (): SmartAgent => {
    const sa = useContext(SmartAgentContext);

    return sa.sa;
};

const useContact = (): Contact | undefined => {
    const sa = useContext(SmartAgentContext);

    return sa.contact;
};

const useAgent = (): User | undefined => {
    const sa = useContext(SmartAgentContext);

    return sa.agent;
};

export { SmartAgentProvider, useSmartAgent, useContact, useAgent };
