import React from 'react';
import { useAttendeeAudioStatus } from 'amazon-chime-sdk-component-library-react';
import MutedIcon from 'images/muted.svg';
import CrossIcon from 'images/cross.svg';
import './tile.scss';
import RoundButton from 'components/RoundButton';

interface Props {
    attendeeId: string;
    isLocal?: boolean;
    name?: string;
    onRemove?: () => void;
}

const Tile: React.FC<Props> = ({ attendeeId, children, isLocal, name, onRemove }) => {
    const { muted } = useAttendeeAudioStatus(attendeeId);

    return (
        <div className="tile">
            {children}
            {muted && !isLocal && (
                <span className="tile__muted">
                    <img src={MutedIcon} alt="Muted" width={20} height={20} />
                </span>
            )}
            {!isLocal && onRemove && (
                <RoundButton onClick={onRemove} size="medium" className="tile__remove">
                    <img src={CrossIcon} alt="Remove" />
                </RoundButton>
            )}
            <span className="tile__name">{isLocal ? 'You' : name || 'Unknown'}</span>
        </div>
    );
};

export default Tile;
