import axios from 'axios';

const urls: Record<string, string> = {
    local: 'http://localhost:3001/local',
    dev: 'https://dev-api.capita-videoassessment.com',
    prod: 'https://api.capita-videoassessment.com',
};

const baseURL = process.env.REACT_APP_API || urls[process.env.REACT_APP_ENV!] || urls.local;

export interface MeetingResponse {
    Meeting: {
        ExternalMeetingId: string;
        MediaPlacement: object;
        MediaRegion: string;
        MeetingId: string;
    };
    Attendee: {
        AttendeeId: string;
        ExternalUserId: string;
        JoinToken: string;
    };
}

export async function startMeeting(data: any) {
    const response = await axios({
        baseURL,
        url: '/meetings',
        method: 'POST',
        data,
    });
    return response.data as MeetingResponse;
}

export async function endMeeting(meetingId: string) {
    const response = await axios({
        baseURL,
        url: `/meetings/${meetingId}`,
        method: 'DELETE',
    });
    return response.data;
}

export async function getAttendee(meetingId: string, externalId: string) {
    const response = await axios({
        baseURL,
        url: `/meetings/${meetingId}/attendees/${externalId}`,
        method: 'GET',
    });
    return response.data;
}
interface Join {
    externalId?: string;
}
export async function joinMeeting(meetingId: string, data: Join) {
    const response = await axios({
        baseURL,
        url: `/meetings/${meetingId}/attendees`,
        method: 'POST',
        data,
    });
    return response.data as MeetingResponse;
}

interface Invite {
    participantType: string;
    email?: string;
    number?: string;
}
export async function sendInvite(meetingId: string, data: Invite) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}/invite`,
        method: 'POST',
        data,
    });
}

export async function deleteAttendee(meetingId: string, attendeeId: string) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}/attendees/${attendeeId}`,
        method: 'DELETE',
    });
}

export async function deleteMeeting(meetingId: string) {
    await axios({
        baseURL,
        url: `/meetings/${meetingId}`,
        method: 'DELETE',
    });
}

type Results = Record<string, string>;
export async function postSurveyResults(meetingId: string, externalId: string, results: Results) {
    await axios({
        baseURL,
        url: `/meeting/${meetingId}/survey-results`,
        method: 'POST',
        data: {
            externalId,
            results,
        },
    });
}
