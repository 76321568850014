import React, { useState, useEffect } from 'react';
import { joinMeeting, MeetingResponse, postSurveyResults } from 'services/api';
import Header from 'components/Header';
import LinkExpired from 'views/Customer/LinkExpired';
import Loading from 'components/Loading';
import CustomerMeeting from './CustomerMeeting';
import Survey, { Results } from './Survey';
import './customer.scss';
import Terms from './Terms';
import { useMeetingStatus, MeetingStatus } from 'amazon-chime-sdk-component-library-react';

interface Props {
    meetingId: string;
    externalId?: string;
}

const CustomerView: React.FC<Props> = ({ meetingId, externalId }) => {
    const [meeting, setMeeting] = useState<MeetingResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(!meetingId);
    const [ended, setEnded] = useState<boolean>(false);
    const [terms, setTerms] = useState<boolean>(false);
    const status = useMeetingStatus();

    const loadMeeting = async (meetingId: string) => {
        try {
            setLoading(true);
            const m = await joinMeeting(meetingId, {
                externalId,
            });
            setMeeting(m);
        } catch (ex) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (terms && meetingId) loadMeeting(meetingId);
        // eslint-disable-next-line
    }, [meetingId, terms]);

    useEffect(() => {
        if ((meeting && status === MeetingStatus.Loading) || status === MeetingStatus.Ended) {
            onEnded();
        }
        // eslint-disable-next-line
    }, [status]);

    const onEnded = () => {
        setEnded(true);
    };

    const onComplete = async (results: Results) => {
        if (!meeting) return;
        const { Meeting, Attendee } = meeting;
        await postSurveyResults(Meeting.MeetingId, Attendee.ExternalUserId, results);
    };

    const onAccept = () => {
        setTerms(true);
        localStorage.setItem('accepted-terms', 'true');
    };

    return (
        <main className="customer">
            <Header />
            {!terms ? (
                <Terms onNext={onAccept} />
            ) : loading ? (
                <Loading />
            ) : error ? (
                <LinkExpired />
            ) : ended ? (
                <Survey onComplete={onComplete} />
            ) : meeting ? (
                <CustomerMeeting meeting={meeting} />
            ) : null}
        </main>
    );
};

export default CustomerView;
