import React, { useEffect, useContext, useState } from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';

interface ISelectedVideoContext {
    selectedAttendeeId: string | null;
    setSelectedAttendeeId: (attendeeId: string) => void;
    setLocalAttendeeId: (attendeeId: string) => void;
    localAttendeeId: string;
}

export const SelectedVideoContext = React.createContext<ISelectedVideoContext>({} as any);

const SelectedVideoProvider: React.FC = ({ children }) => {
    const [localAttendeeId, setLocalAttendeeId] = useState('');
    const { roster } = useRosterState();
    const [selectedAttendeeId, setSelectedAttendeeId] = useState(localAttendeeId);

    useEffect(() => {
        if (selectedAttendeeId === localAttendeeId || !localAttendeeId) return;
        const selected = roster[selectedAttendeeId];
        if (!selected) {
            setSelectedAttendeeId(localAttendeeId);
        }
    }, [roster, localAttendeeId, selectedAttendeeId]);

    return (
        <SelectedVideoContext.Provider
            value={{
                selectedAttendeeId,
                setSelectedAttendeeId,
                setLocalAttendeeId,
                localAttendeeId,
            }}
        >
            {children}
        </SelectedVideoContext.Provider>
    );
};

const useSelectedVideo = (): ISelectedVideoContext => {
    const sv = useContext(SelectedVideoContext);

    return sv;
};

export { SelectedVideoProvider, useSelectedVideo };
