import React from 'react';
import { Button } from '@missionlabs/smartagent-app-components';
import {
    LocalVideo,
    RemoteVideo,
    useLocalVideo,
    useRemoteVideoTileState,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { useSelectedVideo } from 'context/SelectedVideo';
import BlurVideo from 'components/BlurVideo';
import NoVideo from 'components/NoVideo';
import Tile from 'components/Tile';

interface Props {
    blur?: boolean;
    onRemove?: (attendeeId: string) => void;
}
const SelectedTile: React.FC<Props> = ({ blur, onRemove }) => {
    const { selectedAttendeeId, localAttendeeId } = useSelectedVideo();
    const { attendeeIdToTileId } = useRemoteVideoTileState();
    const { roster } = useRosterState();

    if (!selectedAttendeeId) return null;

    const attendee = roster[selectedAttendeeId];

    const tildId = attendeeIdToTileId[selectedAttendeeId];

    if (selectedAttendeeId === localAttendeeId) {
        return (
            <Tile attendeeId={selectedAttendeeId} isLocal>
                <OverrideLocalVideo blur={blur} />
            </Tile>
        );
    }

    const onTileRemove = onRemove ? () => onRemove(selectedAttendeeId) : undefined;

    return (
        <Tile attendeeId={selectedAttendeeId} onRemove={onTileRemove} name={attendee?.name}>
            {tildId ? <RemoteVideo tileId={tildId} /> : <NoVideo />}
        </Tile>
    );
};

const OtherTiles: React.FC<Props> = ({ blur, onRemove }) => {
    const { attendeeIdToTileId } = useRemoteVideoTileState();
    const { roster } = useRosterState();
    const { selectedAttendeeId, setSelectedAttendeeId, localAttendeeId } = useSelectedVideo();

    const renderAttendee = (attendeeId: string) => {
        const attendee = roster[attendeeId];
        const tileId = attendeeIdToTileId[attendeeId];
        if (attendeeId === selectedAttendeeId) return null;
        if (attendeeId === localAttendeeId) {
            return (
                <Tile attendeeId={attendeeId} key={attendeeId} isLocal>
                    <Button
                        onClick={() => setSelectedAttendeeId(attendeeId)}
                        className="tile-button"
                    >
                        <OverrideLocalVideo blur={blur} />
                    </Button>
                </Tile>
            );
        }
        const onTileRemove = onRemove ? () => onRemove(attendeeId) : undefined;
        return (
            <Tile
                attendeeId={attendeeId}
                key={attendeeId}
                onRemove={onTileRemove}
                name={attendee?.name}
            >
                <Button onClick={() => setSelectedAttendeeId(attendeeId)} className="tile-button">
                    {tileId ? <RemoteVideo tileId={tileId} /> : <NoVideo />}
                </Button>
            </Tile>
        );
    };

    return <>{Object.keys(roster).map(attendeeId => renderAttendee(attendeeId))}</>;
};

const OverrideLocalVideo: React.FC<Omit<Props, 'onRemove'>> = ({ blur }) => {
    const { isVideoEnabled } = useLocalVideo();
    if (!isVideoEnabled) {
        return <NoVideo />;
    }
    return blur ? <BlurVideo /> : <LocalVideo />;
};

export { SelectedTile, OtherTiles };
