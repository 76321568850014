import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Form, Input } from '@missionlabs/smartagent-app-components';
import './add-participant.scss';
import { ClipLoader } from 'react-spinners';

interface Props {
    initialNumber?: string;
    onAddParticipant: (participant: string, type: string) => void;
    loading?: boolean;
    error?: boolean;
}

const options = [
    {
        label: 'claimant',
        data: 'claimant',
    },
    {
        label: 'third party',
        data: 'third party',
    },
    {
        label: 'observer',
        data: 'observer',
    },
];

const AddParticipant: React.FC<Props> = ({ initialNumber, onAddParticipant, loading, error }) => {
    const [participant, setParticipant] = useState(initialNumber ?? '');
    const [type, setType] = useState(initialNumber ?? '');

    useEffect(() => {
        if (initialNumber) setParticipant(initialNumber);
    }, [initialNumber]);

    const onSubmit = () => {
        if (!participant) return;
        onAddParticipant(participant, type);
    };

    return (
        <Form onSubmit={onSubmit} className="add-participant">
            <Input
                required={true}
                label="Add Participant"
                onChange={(value: any) => setParticipant(value)}
                value={participant}
            />
            <Dropdown
                label="Participant Type"
                required
                value={type}
                options={options}
                onChange={option => setType(option.label)}
            />
            <Button className="add-participant__add" styling="primary">
                {loading ? <ClipLoader color="#fff" size={20} /> : 'Send invite'}
            </Button>
            {error && (
                <span className="add-participant__error">
                    There was an error sending the invite
                </span>
            )}
        </Form>
    );
};

export default AddParticipant;
