import React, { useEffect } from 'react';
import AgentView from './views/Agent';
import CustomerView from './views/Customer';
import { Box } from '@missionlabs/smartagent-app-components';
import { getParameterByName } from './utils';
import { useContact } from 'context/SmartAgent';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { getAttendee } from 'services/api';

const isEmbedded = window.parent !== window;
const meetingId = getParameterByName('meetingId') ?? undefined;
const externalId = getParameterByName('externalId') ?? undefined;

const App: React.FC<{}> = () => {
    const contact = useContact();
    const meetingManager = useMeetingManager();

    useEffect(() => {
        meetingManager.getAttendee = async (chimeAttendeeId: string, externalUserId?: string) => {
            if (!meetingManager.meetingId) return {};
            try {
                const attendee = await getAttendee(meetingManager.meetingId, externalUserId!);
                return {
                    name: attendee.name || attendee.email || attendee.number || 'Unknown',
                };
            } catch (ex) {
                return {};
            }
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isEmbedded ? (
                <AgentView number={contact?.customerEndpointAddress} />
            ) : (meetingId && externalId) || process.env.REACT_APP_ENV === 'prod' ? (
                <CustomerView meetingId={meetingId!} externalId={externalId} />
            ) : (
                <Box alt header={<h1>Video Calling</h1>} collapse>
                    <AgentView meetingId={meetingId} />
                </Box>
            )}
        </>
    );
};

export default App;
