import React, { useEffect, useState } from 'react';
import { MeetingResponse } from 'services/api';
import './customer-meeting.scss';
import useOrientation from 'hooks/useOrientation';
import { SelectedTile, OtherTiles } from 'components/SelectedTiles';
import ConfirmDisconnect from './ConfirmDisconnect';
import {
    useLocalVideo,
    useMeetingManager,
    useSelectVideoQuality,
} from 'amazon-chime-sdk-component-library-react';
import Controls from 'components/Controls';
import { useSelectedVideo } from 'context/SelectedVideo';

interface Props {
    meeting: MeetingResponse;
}

const CustomerMeeting: React.FC<Props> = ({ meeting }) => {
    const [loading, setLoading] = useState(false);
    const meetingManager = useMeetingManager();
    const { type } = useOrientation();
    const [confirm, setConfirm] = useState(false);
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { setLocalAttendeeId } = useSelectedVideo();
    const selectVideoQuality = useSelectVideoQuality();

    useEffect(() => {
        load();
        setLocalAttendeeId(meeting.Attendee.AttendeeId);
        // eslint-disable-next-line
    }, []);

    const load = async () => {
        setLoading(true);
        await meetingManager.join({
            meetingInfo: meeting.Meeting,
            attendeeInfo: meeting.Attendee,
        });
        await meetingManager.start();
        setLoading(false);
    };

    useEffect(() => {
        if (!loading && !isVideoEnabled) {
            //Get some weird behavior on phones if not set to 720p
            selectVideoQuality('720p');
            toggleVideo();
        }
        // eslint-disable-next-line
    }, [loading]);

    const onToggleConfirm = () => {
        setConfirm(!confirm);
    };

    const onLeave = () => {
        meetingManager.leave();
    };

    const isLandscape = type.includes('landscape');

    return (
        <section className="customer-meeting">
            <div className={`customer-meeting__attendees ${isLandscape ? 'landscape' : ''}`}>
                <OtherTiles />
            </div>
            <div className="customer-meeting__selected">
                <SelectedTile />
            </div>
            <Controls onLeave={onToggleConfirm} />
            {confirm && <ConfirmDisconnect onClose={onToggleConfirm} onDisconnect={onLeave} />}
        </section>
    );
};

export default CustomerMeeting;
