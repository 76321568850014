import React, { useState, useEffect } from 'react';
import {
    startMeeting,
    MeetingResponse,
    joinMeeting,
    deleteAttendee,
    sendInvite,
    deleteMeeting,
} from 'services/api';
import AgentMeeting from './AgentMeeting';
import AddParticipant from './AddParticipant';
import './agent.scss';
import {
    useMeetingStatus,
    MeetingStatus,
    useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { Button, Popup } from '@missionlabs/smartagent-app-components';
import { ClipLoader } from 'react-spinners';
import { useAgent } from 'context/SmartAgent';

interface Props {
    meetingId?: string;
    number?: string;
}

enum ParticipantStatus {
    NO_STATUS = 0,
    SHOW_POPUP = 1,
    SENDING_INVITE = 2,
    INVITE_ERROR = 3,
}

const App: React.FC<Props> = ({ meetingId, number }) => {
    const [meeting, setMeeting] = useState<MeetingResponse | null>(null);
    const [participantStatus, setParticipantStatus] = useState<ParticipantStatus>(
        ParticipantStatus.NO_STATUS
    );
    const status = useMeetingStatus();
    const { roster } = useRosterState();
    const [loading, setLoading] = useState<boolean>(false);
    const agent = useAgent();

    useEffect(() => {
        if (meetingId) {
            loadMeeting(meetingId);
        }
    }, [meetingId]);

    const getInitialNumber = () => {
        return Object.keys(roster).find(key => roster[key].externalUserId === number) ? '' : number;
    };

    const loadMeeting = async (meetingId: string) => {
        setLoading(true);
        try {
            setMeeting(await joinMeeting(meetingId, {}));
        } catch (ex) {}
        setLoading(false);
    };

    const onAddParticipant = async (number: string, participantType: string) => {
        try {
            setParticipantStatus(ParticipantStatus.SENDING_INVITE);
            const params = number.includes('@')
                ? { email: number, participantType }
                : { number, participantType };
            await sendInvite(meeting!.Meeting.MeetingId, params);
            setParticipantStatus(ParticipantStatus.NO_STATUS);
        } catch (ex) {
            setParticipantStatus(ParticipantStatus.INVITE_ERROR);
        }
    };

    const onStart = async () => {
        setLoading(true);
        setMeeting(
            await startMeeting({
                name: agent?.name ?? 'Capita Agent',
            })
        );
        setLoading(false);
    };

    const onDisconnect = async (attendeeId: string) => {
        if (!meeting) return;
        await deleteAttendee(meeting.Meeting.MeetingId, attendeeId);
    };

    useEffect(() => {
        if ((meeting && status === MeetingStatus.Loading) || status === MeetingStatus.Ended) {
            onEnded();
        }
        // eslint-disable-next-line
    }, [status]);

    const onEnded = async () => {
        if (!meeting) return;
        await deleteMeeting(meeting.Meeting.MeetingId);
        setMeeting(null);
    };

    const toggleOnAdd = () => {
        if (participantStatus === ParticipantStatus.NO_STATUS) {
            setParticipantStatus(ParticipantStatus.SHOW_POPUP);
        } else {
            setParticipantStatus(ParticipantStatus.NO_STATUS);
        }
    };

    return (
        <>
            {meeting ? (
                <AgentMeeting meeting={meeting} onAdd={toggleOnAdd} onDisconnect={onDisconnect} />
            ) : (
                <div className="not-connected">
                    <Button styling="primary" onClick={onStart}>
                        {loading ? <ClipLoader color="#fff" size={20} /> : 'Start'}
                    </Button>
                </div>
            )}
            {participantStatus ? (
                <Popup center closeOnClickOutside onClose={toggleOnAdd} className="add-popup">
                    <AddParticipant
                        loading={participantStatus === ParticipantStatus.SENDING_INVITE}
                        error={participantStatus === ParticipantStatus.INVITE_ERROR}
                        onAddParticipant={onAddParticipant}
                        initialNumber={getInitialNumber()}
                    />
                </Popup>
            ) : null}
        </>
    );
};

export default App;
