import React from 'react';
import UserIcon from 'images/user.svg';
import './no-video.scss';

const NoVideo: React.FC = () => {
    return (
        <div className="no-video">
            <img src={UserIcon} alt="User" height={30} width={30} />
        </div>
    );
};

export default NoVideo;
