import { useAudioVideo, useVideoInputs } from 'amazon-chime-sdk-component-library-react';
import React, { useRef, useEffect, useState } from 'react';
import loadBodyPix, { BodyPix } from 'services/body-pix';
import { drawBokehEffect } from '@tensorflow-models/body-pix';
interface Props {
    className?: string;
}

const BlurVideo: React.FC<Props> = ({ className }) => {
    const canvasEl = useRef<null | HTMLCanvasElement>(null);
    const videoEl1 = useRef<null | HTMLVideoElement>(null);
    const videoEl = useRef<HTMLVideoElement>(document.createElement('video'));
    const af = useRef<null | number>(null);
    const net = useRef<null | BodyPix>(null);
    const stream = useRef<null | MediaStream>(null);
    const video = useVideoInputs();
    const av = useAudioVideo();
    const [selectedDevice] = useState(video.selectedDevice);

    useEffect(() => {
        if (av) doBlur();
        // eslint-disable-next-line
        return () => {
            if (stream.current) {
                const tracks = stream.current.getTracks();
                tracks.forEach(t => t.stop());
            }
        };
        // eslint-disable-next-line
    }, [av, video.selectedDevice]);

    useEffect(() => {
        return () => {
            av?.chooseVideoInputDevice(selectedDevice);
        };
        // eslint-disable-next-line
    }, []);

    const wait = (video: HTMLVideoElement) => {
        if (video.readyState === 4) {
            return;
        }
        return new Promise(resolve => (video.onloadeddata = resolve));
    };

    const getStream = async (): Promise<MediaStream> => {
        //@ts-ignore
        const c = av?.deviceController.activeDevices.video?.constraints ?? { video: true };
        const params = video.selectedDevice
            ? {
                  video: {
                      ...c.video,
                      deviceId: {
                          exact: video.selectedDevice,
                      },
                  },
              }
            : c;
        return await navigator.mediaDevices.getUserMedia({ ...params, audio: false });
    };

    const doBlur = async () => {
        stream.current = await getStream();
        const settings = stream.current.getVideoTracks()[0].getSettings();
        const { width, height } = settings!;
        const r = width! / height!;
        videoEl.current!.width = window.innerWidth;
        videoEl.current!.height = window.innerWidth / r;
        videoEl.current!.srcObject = stream.current;
        net.current = await loadBodyPix();
        await wait(videoEl.current!);
        videoEl.current!.play();

        requestAnimationFrame(animate);
        if (!canvasEl.current) return;
        // @ts-ignore
        await av?.chooseVideoInputDevice(canvasEl.current?.captureStream());
    };

    const animate = async () => {
        const segmentation = await net.current!.segmentPerson(videoEl.current!, {
            segmentationThreshold: 0.3,
            maxDetections: 1,
            flipHorizontal: false,
        });
        const backgroundBlurAmount = 15;
        const edgeBlurAmount = 10;
        const flipHorizontal = false;
        // Draw the image with the background blurred onto the canvas. The edge between
        // the person and blurred background is blurred by 3 pixels.
        if (!canvasEl.current) return;
        drawBokehEffect(
            canvasEl.current!,
            videoEl.current!,
            segmentation,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal
        );
        af.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        return () => cancelAnimationFrame(af.current!);
    }, []);
    return (
        <>
            <canvas ref={canvasEl} id="canvas" className={className} />
            <video ref={videoEl1} style={{ display: 'none' }} />
        </>
    );
};

export default BlurVideo;
