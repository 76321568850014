import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import '../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import '../node_modules/react-resizable/css/styles.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { MeetingProvider, darkTheme } from 'amazon-chime-sdk-component-library-react';
import { SmartAgentProvider } from './context/SmartAgent';
import { LogLevel } from 'amazon-chime-sdk-js';
import { SelectedVideoProvider } from 'context/SelectedVideo';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
            <MeetingProvider logLevel={LogLevel.INFO}>
                <SmartAgentProvider>
                    <SelectedVideoProvider>
                        <App />
                    </SelectedVideoProvider>
                </SmartAgentProvider>
            </MeetingProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
